/**
 * Header Search Form
 */
.location-form,
.search-form {
	align-self: stretch;
	border-top: 1px solid $yellowLight;
	z-index: 1000;
    
    &.search-form--active {
        #search-input {                
            border-radius: 0;
            width: 10rem;
        }
    }

	&__form {
		transition: .25s ease-in-out;
		width: 100%;
		z-index: 10;
	}

	&__container {
		display: flex;
		position: relative;
		width: 100%;
	}

	&__field {
		background: $white;
		border: none;
		display: block;
		height: 100%;
		width: 100%;
		padding: 1em;
	}

	&__button {
		background: $gold;
		border: none;
		position: relative;
			top: -0.5em;
		padding: 0;
		width: 100%;
	}

	&__button--search {
		background-color: $gold;
		border: none;
		display: block;
		height: 2.5rem;
		transition: .5s all ease;
		width: 2.5rem;

		&:focus,
		&:hover {
			background-color: transparent;
			border: 0;
            
            svg {
                path {
                    fill: $gold;
                }
            }
		}
        
        svg {         
            height: 1rem;
            width: 1rem;
        }
	}

	&__button--search {
		background-position: center;
		position: absolute;
			top: 50%;
			right: 0;
		transform: translateY(-50%);
	}

	@include media($mobileNavBP) {
		border: none;
		
		&__form {
            height: auto;
			overflow: hidden;
			width: 20em;
		}
	}
}
