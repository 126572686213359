/**
 * To add a new font:
 * DBS>Interactive
 *
 * Take the fonts and upload them here:
 * https://transfonter.org/
 *
 * Download the resulting files and copy the woff and woff2
 * files into the appropriate directory.
 *
 * Make sure that the font-weight and font-style match the appropriate font files.
 *
 * We generally remove all of the unnecessary glyphs from the font family to make the files as
 * small as possible. If the font is a Google Font, you can copy all of its available glyphs
 * by clicking the "Glyphs" tab on the font's page. Paste those into the "characters" field on 
 * Transfonter and delete all of the characters that are not typically going to be used on an  
 * English language site. When you do this correctly the files downloaded from Transfonter will
 * be prefixed with "subset-". If the site has multiple languages, then don't do that.
 */

@font-face {
	font-family: 'Encode Sans Condensed';
	src: url('../fonts/encode-sans-condensed/subset-EncodeSansCondensed-Light.woff2') format('woff2'),
		 url('../fonts/encode-sans-condensed/subset-EncodeSansCondensed-Light.woff') format('woff');
    font-weight: 300;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Encode Sans Condensed';
	src: url('../fonts/encode-sans-condensed/subset-EncodeSansCondensed-Regular.woff2') format('woff2'),
		 url('../fonts/encode-sans-condensed/subset-EncodeSansCondensed-Regular.woff') format('woff');
	font-weight: 400;
	font-display: swap;
	font-style: italic;
}

@font-face {
	font-family: 'Encode Sans Condensed';
	src: url('../fonts/encode-sans-condensed/subset-EncodeSansCondensed-SemiBold.woff2') format('woff2'),
		 url('../fonts/encode-sans-condensed/subset-EncodeSansCondensed-SemiBold.woff') format('woff');
	font-weight: 600;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Encode Sans Condensed';
	src: url('../fonts/encode-sans-condensed/subset-EncodeSansCondensed-Bold.woff2') format('woff2'),
		 url('../fonts/encode-sans-condensed/subset-EncodeSansCondensed-Bold.woff') format('woff');
	font-weight: 700;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/lato/subset-Lato-Regular.woff2') format('woff2'),
		 url('../fonts/lato/subset-Lato-Regular.woff') format('woff');
    font-weight: 400;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/lato/subset-Lato-Italic.woff2') format('woff2'),
		 url('../fonts/lato/subset-Lato-Italic.woff') format('woff');
	font-weight: 400;
	font-display: swap;
	font-style: italic;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/lato/subset-Lato-Bold.woff2') format('woff2'),
		 url('../fonts/lato/subset-Lato-Bold.woff') format('woff');
	font-weight: 700;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/lato/subset-Lato-BoldItalic.woff2') format('woff2'),
		 url('../fonts/lato/subset-Lato-BoldItalic.woff') format('woff');
	font-weight: 700;
	font-display: swap;
	font-style: italic;
}
