/**
 * Cookie Popup styles
 * DBS>Interactive
 */

 .cookie-popup {
	background: $grayDarker;
	box-shadow: 0 0 1rem -.5rem $black;
    color: $whiteDark;
	font-size: .9rem;
	overflow-y: auto;
	padding: 1em;
	position: fixed;
		left: 0;
		bottom: 0;
	width: 100%;
	z-index: 3000;

	.contain {
		display: flex;
			flex-direction: column;
			justify-content: space-between;
		padding: 0 1em;
	}

	&__heading {
		border-bottom: 1px solid $yellow;
		margin-bottom: 0.5rem;
		padding-bottom: .5rem;
	}

	&__content {
		line-height: 1.5;
		margin-bottom: $spacing;
	}

	&__learn-more {
		-webkit-appearance: none;
		appearance: none;
		background: transparent;
		border: none;
		padding: 0;
		text-decoration: underline;
	}

	&__buttons {
		max-width: 20em;

		.button {
			display: block;
			padding: .5em;
			width: 100%;
		}
	}

	@include media($screen-sm) {
		.contain {
			align-items: center;
			flex-direction: row;
			justify-content: space-between;
		}

		&__buttons {width: 40%;}

		&__content {
			margin-bottom: 0;
			padding-right: 2em;
			width: 60%;
		}
	}
}