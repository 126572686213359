/**
 * DBS>Interactive
 * General Theme Type classes
 * These classes are generic repeatable patterns of type styles that can be
 * extended by other classes to maintain consistency throughout the theme.
 *
 * Recommended usage:
 * 		@extend %button-type;
 */


/**
 * Default Typography
 */
html {
	@extend %default-type;
	font-size: $bodyFontSize;
}

body {
	@extend %default-type;
	font-size: $bodyFontSize;
	font-weight: 400;
	line-height: $bodyLineHeight;
}


nav {
	@extend %header-type;
}


h1, h2, h3, h4, h5, h6 {
	@extend %header-type;
	margin-top: 0;
	margin-bottom: $vSpacing * 1;
	text-rendering: optimizeLegibility;

	a { text-decoration: none; }
}


.h1, h1 { @include font-scale( 5, $headingFontSize); }
.h2, h2 { @include font-scale( 4, $headingFontSize); }
.h3, h3 { @include font-scale( 3, $headingFontSize); }
.h4, h4 { @include font-scale( 2, $headingFontSize); }
.h5, h5 { @include font-scale( 1, $headingFontSize); }
.h6, h6 { @include font-scale( 0, $headingFontSize); }

@include media($screen-sm) {
    .h1, h1 { @include font-scale( 6, $headingFontSize); }
    .h2, h2 { @include font-scale( 5, $headingFontSize); }
    .h3, h3 { @include font-scale( 4, $headingFontSize); }
    .h4, h4 { @include font-scale( 3, $headingFontSize); }
    .h5, h5 { @include font-scale( 2, $headingFontSize); }
    .h6, h6 { @include font-scale( 1, $headingFontSize); }
}

.eyebrow {
	font-size: 1rem;
    font-weight: 300;
	margin-bottom: $spacing/2;
	text-transform: uppercase;
}


a {
	color: $linkColor;
	font-weight: bold;
	text-decoration: underline;
	transition: color ease-in-out 0.25s;
	&:hover, &:focus, &:active { color: darken($linkColor, 10%); text-decoration: none;}
}

.bg-dark a {
	color: $white;
	&:hover, &:focus, &:active { color: darken($white, 10%); }
}


p {
	margin-top: 0;
	margin-bottom: $spacing;
}

.dropcap {
	color: $primaryColor;
	float: left;
	font-size: 6.25em;
	font-weight: 400;
	line-height: 90px;
	margin-bottom: -.031em;
	margin-top: .03em;
	padding-right: .5rem;
}

.font_small,
small { font-size: 0.707em; }

.intro-text,
.bigger-text {
	font-size: 1.125rem;
}

b, strong {font-weight: bold;}

.lato {font-family: $font-lato;}
.encode-sans-condensed {font-family: $font-encode-sans-condensed;}

// Color Classes
.white {color: $white !important;}
.dark-white, .alabaster {color: $whiteDark !important;}
.light-yellow, .banana-mania {color: $yellowLight !important;}
.light-blue, .jungle-mist {color: $blueLight !important;}
.yellow, .flax {color: $yellow !important;}
.orange, .sun {color: $orange !important;}
.gold, .tussock {color: $gold !important;}
.dark-gold, .kumera {color: $goldDark !important;}
.gray, .dove-gray {color: $gray !important;}
.dark-gray, .shuttle-gray {color: $grayDark !important;}
.darker-gray, .tundora {color: $grayDarker !important;}
.purple, .mulled-wine {color: $purple !important;}
.blue, .chathams-blue {color: $blue !important;}
.dark-blue, .cello {color: $blue !important;}
.darker-blue, .martinique {color: $purpleDark !important;}
.brown, .brown-tumbleweed {color: $brown !important;}
.darkest-gray, .shark {color: $grayDarkest !important;}
.light-black, .mine-shaft {color: $blackLight !important;}
.black {color: $black !important;}


// Type Style Classes
.caps {text-transform: uppercase;}
.underline {text-decoration: underline;}

// Alignment Classes
.text-center {text-align: center;}
.text-left {text-align: left;}
.text-right {text-align: right;}

// Font Weight Classes
.normal {font-weight: 400;}
.semi-bold {font-weight: 600;}
.bold {font-weight: 700;}
.extra-bold {font-weight: 900;}
