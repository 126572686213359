/**
 * Header styles
 * DBS>Interactive
 *
 * 		site-header - the page's main header element
 * 			site-header__branding - the company logo
 * 			site-header__navigation - the site navigation
 * 				NOTE: Put navigation styles in _navigation.scss.
 *
 * ************************************************************************
 * This file is for the general header/branding styles. For specific
 * menu/navigation files, use _navigation.scss.
 * ************************************************************************
 */

.site-header {
    background-color: $grayDarkest;   // default for Citadel/Pavilion; gets overwritten on Omnia 
	position: relative;
	z-index: 1000;

	h1 { // Offset bottom margin for exact centering
		padding-top: 1rem;
	}

	&__container {
		display: flex;
			align-items: center;
			justify-content: space-between;
		padding: 0 $spacing;
        position: relative;
	}

	/**
	 * Company logo in site header
	 */
	&__branding {

		img {width: 100%;}

		/**
		 * Anchor tag that wraps svg element (logo graphic) for site homepage link
		 */
		a {
			display: block;
			line-height: 0;
			margin-left: -3rem; // Move the left edge of the logo all the way to the edge of the container
			max-width: 20rem;
		}
	}
}

    