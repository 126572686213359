/**
 * Theme Variables - colors, font sizes, breakpoint etc.
 * All the variables will goes here based on project tech specification
 * DBS>Interactive
 */
$libraryPath: '/web';

// Colors
$white: #ffffff;
$whiteDark: #F8F7F8;    // alabaster
$yellowLight: #FCEABE;  // bananaMania
$blueLight: #C2D1D9;   // jungleMist
$yellow: #EFD683;    // flax
$orange: #FCB315;  // sun
$gold: #BF8E40;  // tussock
$goldDark: #875E1D;     // kumera
$gray: #676767;     // doveGray
$grayDark: #505D68;  // shuttleGray
$grayDarker: #4D4D4D;  // tundora
$purple: #52446F;   // mulledWine
$blue: #164767; // chathamsBlue + cello (244563)
$blueDark: #182F44; // bigStone
$blueDarker: #25303A;    // ebonyClay
$purpleDark: #403158;   // martinique
$brown: #37270F;  // brownTumbleweed
$grayDarkest: #27282A;    // shark
$blackLight: #212121;    // mineShaft
$black: #020202;

$alabaster: $whiteDark;
$bananaMania: $yellowLight;
$jungleMist: $blueLight;
$flax: $yellow;
$sun: $orange;
$tussock: $gold;
$kumera: $goldDark;
$doveGray: $gray;
$shuttleGray: $grayDark;
$tundora: $grayDarker;
$mulledWine: $purple;
$chathamsBlue: $blue;
$cello: $blue;
$bigStone: $blueDark;
$ebonyClay: $blueDarker;
$martinique: $purpleDark;
$brownTumbleweed: $brown;
$shark: $grayDarkest;
$mineShaft: $blackLight;

$primaryColor: $gold;
$secondaryColor: $goldDark;

/**
 * Fonts
 */
$font-lato: Lato, Verdana, Arial, sans-serif;
$font-encode-sans-condensed: 'Encode Sans Condensed', 'Trebuchet MS', Arial, sans-serif;
$bodyFont: $font-lato;
$headerFont: $font-encode-sans-condensed;

$textColor: $grayDarkest;
$bodyFontSize: 1em;
$bodyLineHeight: 1.65;
$ratioScale: 1.25;
$linkColor: $textColor;

// Heading Sizing
$headingColor: $grayDarkest;
$headingFontSize: 1em;
$headingLineHeight: 1.25;

// Layout Vars
$containSizeMoreNarrow: 50rem;
$containSizeNarrow: 65rem;
$containSize: 78rem;
$containSizeWide: 85rem;

$spacing: 1.5rem;
$vSpacing: 1.5rem;

// Media Query Break Points
$screen-xs:  30.000em;  //  480px
$screen-sm:  48.000em;  //  768px
$screen-md:  62.000em;  //  992px
$screen-lg:  75.000em;  // 1200px
$screen-xlg: 100.000em; // 1600px

// Max Breakpoints
$screen-xs-max:  $screen-xs  - .001em;
$screen-sm-max:  $screen-sm  - .001em;
$screen-md-max:  $screen-md  - .001em;
$screen-lg-max:  $screen-lg  - .001em;
$screen-xlg-max: $screen-xlg - .001em;

$mobileNavBP:    $screen-md; // Break mobile nav styles
$mobileNavBPMax: $screen-md-max;

/**
 * Type style definitions
 */
%default-type {
	color: $textColor;
	font-family: $bodyFont;
	letter-spacing: 0.025em;
}

%button-type {
	@extend %default-type;
	font-weight: bold;
}

%header-type {
	color: $headingColor;
    font-family: $headerFont;
	font-weight: bold;
	line-height: $headingLineHeight;
}
